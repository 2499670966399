import './index.scss'


// 点击打开移动端导航栏
$('.root-header [data-open="cmenu"]').on('click', function () {
  $('.root-header .nav-mob').addClass('active')
})
// 点击关闭移动端导航栏
$('.root-header .nav-mob .nav-mob-close').on('click', function () {
  $('.root-header .nav-mob').removeClass('active')
  $('.root-header .nav-mob ul li').removeClass('active')
  $('.root-header .nav-mob ul li .sub-slide').slideUp(400)
})
//点击移动端导航列表展开子项
$('.root-header .nav-mob ul li').on('click', function () {
  if (!$(this).hasClass('active')) {
    $(this).addClass('active').siblings().removeClass('active')
    $(this).find('.sub-slide').slideDown(400)
    $(this).siblings().find('.sub-slide').slideUp(400)
  }
})

//电脑端导航状态改变（鼠标经过时）
$(".root-header .header-nav ul li").mouseenter(function () {
  if (!$(this).hasClass('active')) {
    $(this).addClass('hover');
    $(this).siblings('.active').addClass('not-active');
  }
});
$(".root-header .header-nav ul li").mouseleave(function () {
  if (!$(this).hasClass('active')) {
    $('.root-header .header-nav ul li').removeClass('hover');
    $('.root-header .header-nav ul li.active').removeClass('not-active');
  }
});

//点击搜索图标，显示搜索区域
$('.header-search .search-icon').on('click',function () {
  if($(this).hasClass('active')){
    $(this).removeClass('active')
    $('.root-header').removeClass('search-header')
    $('.search-cont').removeClass('active')
  }else{
    $('.root-header').addClass('search-header')
    $(this).addClass('active')
    $('.search-cont').addClass('active')
  }
})
// $(".root-header").mouseleave(function () {
//   setTimeout(() => {
//     $('.header-search .search-icon').removeClass('active')
//     $('.search-cont').removeClass('active')
//     $('.root-header').removeClass('search-header')
//   }, 1000);
// });